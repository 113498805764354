import React from "react";

const matchSettings = (values, queries): any =>
  values[queries.findIndex((q) => matchMedia(q).matches)];

export interface IUseMedia {
  (queries: Array<string>, values: Array<any>, defaultValue?: any): any;
}

const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;

let useMedia: IUseMedia;
useMedia = function useMedia(
  queries: Array<string>,
  values: Array<any>,
  defaultValue?: any
): any {
  const [value, set] = React.useState();

  useIsomorphicLayoutEffect(() => {
    const handler = () => {
      const match =
        typeof window !== "undefined"
          ? matchSettings(values, queries) || defaultValue
          : undefined;

      set(match);
    };
    handler();
    window.addEventListener("resize", handler);

    return () => window.removeEventListener("resize", handler);
  }, []);

  return value;
};

export default useMedia;
