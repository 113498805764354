import React from "react";
import isEqual from "react-fast-compare";
import TransitionLink from "gatsby-plugin-transition-link";
import cn from "classnames";
import { TweenMax } from "gsap";
import * as pagePaths from "@/constants/paths";
import { GatsbyLinkProps } from "gatsby";
import * as css from "./global-navigation.modules.scss";

export type GlobalNavLink = {
  href?: string;
  to?: string;
  target?: string;
  children: JSX.Element | string;
};
export type GlobalNavProps = {
  links: GlobalNavLink[];
  className?: string;
  style?: React.CSSProperties;
};

const PAGE_PATH_VALUES = Object.keys(pagePaths).map((k) => pagePaths[k]);
let footerTween: ReturnType<typeof TweenMax.to>;

export class AniLink extends React.Component<GatsbyLinkProps<any>, null> {
  shouldComponentUpdate(nextProps) {
    return this.props.to !== nextProps.to;
  }

  render() {
    const props = this.props;

    return (
      <TransitionLink
        exit={{
          trigger: ({ node }) => {
            footerTween && footerTween.kill();
            footerTween = TweenMax.to(
              [node, document.querySelector("#global-footer")],
              0.5,
              {
                opacity: 0,
              }
            );
          },
          length: 0.5,
        }}
        entry={{
          delay: 0.5,
          length: 0.5,
          trigger: ({ node }) => {
            const $footer = document.querySelector("#global-footer");

            footerTween && footerTween.kill();

            footerTween = TweenMax.set([node, $footer], { opacity: 0, y: 25 });
            footerTween = TweenMax.to([node, $footer], 0.5, {
              opacity: 1,
              y: 0,
              delay: 0.15,
              clearProps: "all",
            });
          },
        }}
        {...props}
      />
    );
  }
}

// this link will be active when itself or deeper routes
// are current.
// since event page urls start from root /
// we exclude all known app pages to make
// "program" global nav link active
const isPartiallyActive = (
  { isPartiallyCurrent, href, location },
  className,
  activeClassName
) => {
  let pathnameNorm = location.pathname.replace(/^(\/en)?/, "");
  pathnameNorm = pathnameNorm.endsWith("/") ? pathnameNorm : `${pathnameNorm}/`;

  const hrefWoLocale = href.replace(/^(\/en)?/, "");

  const isProgramLink = hrefWoLocale === pagePaths.PROGRAM;
  const isKnownPage = PAGE_PATH_VALUES.indexOf(pathnameNorm) > -1;

  return isPartiallyCurrent || (isProgramLink && !isKnownPage)
    ? { className: cn(className, activeClassName) }
    : { className };
};

export const GlobalNavLink: React.FC<React.ComponentPropsWithoutRef<
  typeof AniLink
>> = React.memo(({ className, activeClassName = "", ...rest }) => {
  return (
    <AniLink
      getProps={(obj) => isPartiallyActive(obj, className, activeClassName)}
      {...rest}
    />
  );
}, isEqual);

const GlobalNav: React.FC<GlobalNavProps> = ({ className, links, style }) => {
  return (
    <nav
      className={cn(
        className,
        `
              hidden desktop:flex justify-between uppercase
              desktop1440:border-b-3 border-b-2
              `
      )}
      style={style}
    >
      {links.map(({ children, to, href, ...rest }, i) => (
        <div className={"relative"} key={i}>
          <div aria-hidden className={"opacity-0 font-extrabold"}>
            {children}
          </div>
          <div
            className={cn("absolute left-0 bottom-0", {
              "text-left": i === 0,
              "text-center": i > 0 && i < links.length - 1,
              "text-right": i === links.length - 1,
            })}
          >
            {to ? (
              <GlobalNavLink
                className={css.navLink}
                activeClassName={"font-extrabold"}
                to={to}
              >
                {children}
              </GlobalNavLink>
            ) : (
              <a href={href} className={css.navLink} {...rest}>
                {children}
              </a>
            )}
          </div>
        </div>
      ))}
    </nav>
  );
};

export default GlobalNav;
