// extracted by mini-css-extract-plugin
export var desktopHeader = "header-modules--desktopHeader--XN1lg";
export var desktopHeaderVisible = "header-modules--desktopHeaderVisible--17iXg";
export var mobHeader = "header-modules--mobHeader--3k2ps";
export var mobHeaderVisible = "header-modules--mobHeaderVisible--M1iB-";
export var mobLogoIcon = "header-modules--mobLogoIcon--3_S5A";
export var mobileMenuRoot = "header-modules--mobileMenuRoot--1mJZS";
export var isOpen = "header-modules--isOpen--3KYbt";
export var mobileMenuInner = "header-modules--mobileMenuInner--2pEip";
export var footerLinksText = "header-modules--footerLinksText--3BwgN";
export var switchLocaleLink = "header-modules--switchLocaleLink--1FX96";