import React, { createContext, useContext } from "react";
import get from "dlv";
import { useCallback, useMemo } from "react";

export const I18nContext = createContext();

export const I18nProvider = ({ children, locale, messages }) => {
  const t = useCallback((path) => get(messages, path, path), []);
  const value = useMemo(
    () => ({
      locale,
      t,
    }),
    []
  );

  return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>;
};

export const useI18n = () => useContext(I18nContext);
