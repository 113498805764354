import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Header from "@/components/header/header";
import { useRoute, localizePath } from "@/utils";
import { useLocation } from "@reach/router";
import { useStateValue } from "@/store";
import {
  HOME_PAGE_OFFSEASON_MODE,
  HOME_PAGE_BEFORE_SEASON_MODE,
} from "@/constants";
import { useI18n } from "@/plugins/i18n";

const HeaderRu = () => {
  const [{ locale }] = useStateValue();
  const { t } = useI18n();

  if (locale !== "ru") {
    return null;
  }

  const route = useRoute();
  const location = useLocation();

  const { layoutHome, layoutFooter } = useStaticQuery(graphql`
    query {
      layoutHome: contentfulLayoutHome(node_locale: { eq: "ru-RU" }) {
        pageMode
      }
      layoutFooter: contentfulLayoutFooter(node_locale: { eq: "ru-RU" }) {
        linksText {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `);
  const isOffseason = layoutHome?.pageMode === HOME_PAGE_OFFSEASON_MODE;
  const isBeforeSeason = layoutHome?.pageMode === HOME_PAGE_BEFORE_SEASON_MODE;

  return (
    <Header
      navLinks={[
        !isOffseason && {
          to: route("program"),
          children: isBeforeSeason
            ? t("routeNames.program_before_season")
            : t("routeNames.program_season"),
        },
        { to: route("about"), children: t("routeNames.about") },
        !isOffseason && { 
          to: route("faq"), 
          children: t("routeNames.faq") 
        },
        { to: route("archive"), children: t("routeNames.archive") },
        { to: route("contacts"), children: t("routeNames.contacts") },
        {
          href: "https://shop.netfest.ru/",
          children: t("routeNames.shop"),
          target: "_blank",
        },
      ].filter(Boolean)}
      switchLocaleLink={{
        href: localizePath(
          locale === "en" ? "ru" : "en",
          location.pathname.replace(/^\/en\//, "/")
        ),
        children: locale === "en" ? t("localeTitles.ru") : t("localeTitles.en"),
        target: "_self",
      }}
      footerLinksText={layoutFooter?.linksText?.childMarkdownRemark?.html}
    />
  );
};

export default HeaderRu;
