import React from "react";
import cn from "classnames";
import "./hamburger.global.scss";

type HamburgerProps = {
  isOpen: boolean;
  className?: string;
};
const Hamburger: React.FC<
  React.ButtonHTMLAttributes<null> & HamburgerProps
> = ({ isOpen, className, ...rest }) => {
  return (
    <button
      className={cn(
        "hamburger hamburger--spin-r",
        { "is-active": isOpen },
        className
      )}
      {...rest}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </button>
  );
};

export default Hamburger;
