import React, { useEffect, useRef } from "react";
import isEqual from "react-fast-compare";
import { graphql } from "gatsby";
import AniLogo from "@/components/kit/ani-logo";
import * as styleConstants from "@/constants/style";
import cn from "classnames";
import { useRoute } from "@/utils";
import { useIntersection } from "react-use";
import Img from "gatsby-image";
import { useStateValue } from "@/store";
import { SET_FOOTER_VISIBLE } from "@/constants/actionTypes";
import LogoSvg from "@/assets/icons/logo.inline.svg";
import * as css from "./footer.modules.scss";
import { AniLink } from "../global-navigation";
import { useI18n } from "@/plugins/i18n";

// const fullChunk = (array: any[], size: number, value: any): any[] => {
//   const result = new Array(Math.ceil(array.length / size));
//   let index = 0;
//   let resIndex = 0;
//   let arr;

//   while (index < array.length) {
//     arr = [
//       ...array.slice(index, (index += size)),
//       ...new Array(size).fill(value),
//     ].slice(0, size);

//     result[resIndex++] = arr;
//   }

//   return result;
// };

type FooterProps = {
  data: {
    footer: any;
  };
};

const Footer: React.FC<FooterProps> = React.memo(({ data }) => {
  useEffect(() => {}, []);
  const { t } = useI18n();

  const footerRef = useRef(null);

  const [{ footerIsVisible }, dispatch] = useStateValue();

  const intersection = useIntersection(footerRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  });

  useEffect(() => {
    if (intersection && !intersection.isIntersecting && footerIsVisible)
      dispatch({ type: SET_FOOTER_VISIBLE, payload: false });
    else if (intersection && intersection.isIntersecting && !footerIsVisible)
      dispatch({ type: SET_FOOTER_VISIBLE, payload: true });
  }, [intersection]);

  const {
    partnersBlockTitle,
    linksText,
    textCol2,
    textCol1,
    legalNoticeText,
    partnersRow1,
    partnersRow2,
    partnersRow3,
    partnersRow4,
    partnersRow5,
    partnersRow6,
    partnersRow7,
    partnersRow8,
    partnersRow9,
    partnersRow10,
  } = data.footer;

  const partnerRows = [
    partnersRow1,
    partnersRow2,
    partnersRow3,
    partnersRow4,
    partnersRow5,
    partnersRow6,
    partnersRow7,
    partnersRow8,
    partnersRow9,
    partnersRow10,
  ].filter(Boolean);

  const route = useRoute();

  const AUTHOGRAPH_HTML = t("madeBy");

  return (
    <div
      id="global-footer"
      ref={footerRef}
      className={styleConstants.container}
    >
      <footer className={"pt-3 desktop:pt-5 border-t-2 desktop1440:border-t-3"}>
        <div className={"text-h2 desktop1280:-ml-1"}>
          <span className={css.partnersBlockTitle}>{partnersBlockTitle}</span>
        </div>
        <div className={"pt-4 desktop1440:pt-8"}>
          {partnerRows.length > 0 &&
            partnerRows.map((items, i) => {
              return (
                <div
                  key={i}
                  className={cn(
                    "desktop1440:border-b-3 border-b-2 flex flex-col tablet:flex-row"
                  )}
                >
                  {items.map((item, j) => (
                    <div key={j} className={"pb-6 desktop:pb-12 w-full"}>
                      {item.title && (
                        <div className={"mt-3 mb-3 desktop:mb-8"}>
                          {item.title}
                        </div>
                      )}
                      <div
                        className={
                          "flex flex-wrap gap-y-4 desktop:gap-y-9 w-full"
                        }
                      >
                        {item.images.map((img, k) => (
                          <div
                            key={k}
                            className={cn(
                              css.partnerImageContainer,
                              "flex items-center justify-center"
                            )}
                          >
                            <Img
                              {...img}
                              loading={"eager"}
                              className={css.partnerImage}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
        </div>

        {linksText && (
          <div
            className={cn(
              `py-6 desktop:py-4 desktop1280:py-6
              desktop1440:border-b-3 border-b-2`
            )}
          >
            <AniLink
              to={route("home")}
              className={"block tablet:hidden mx-auto w-1/4 mb-2"}
            >
              <LogoSvg className={"w-full h-auto"} />
            </AniLink>

            <div
              className={cn(css.linksText, "text-title-2 uppercase")}
              dangerouslySetInnerHTML={{
                __html: linksText.childMarkdownRemark?.html,
              }}
            />
          </div>
        )}

        <div
          className={`
            flex flex-col tablet:flex-row
            desktop1440:border-b-3 border-b-2
            py-6 tablet:py-4 desktop:pt-5 desktop:pb-6 desktop1280:pb-10 desktop1280:pt-8
            `}
        >
          <div
            className={
              "w-full tablet:w-1/2 desktop:w-2/12 text-center tablet:text-left"
            }
          >
            <AniLink
              to={route("home")}
              className={"hidden tablet:block tablet:w-5/12 desktop:w-full"}
            >
              <AniLogo className={"w-full h-auto"} />
            </AniLink>

            <div
              className={cn(
                "desktop:hidden w-full tablet:pr-14 tablet:mt-10",
                css.columnText
              )}
              dangerouslySetInnerHTML={{
                __html: textCol2?.childMarkdownRemark?.html,
              }}
            />
          </div>
          <div className={"hidden desktop:block w-1/12"}>&nbsp;</div>
          <div
            className={cn(
              "tablet:w-1/2 desktop:w-3/12 text-center tablet:text-left mt-7 tablet:mt-0",
              css.columnText
            )}
            dangerouslySetInnerHTML={{
              __html: textCol1?.childMarkdownRemark?.html,
            }}
          />
          <div className={"hidden desktop:block w-1/24"}>&nbsp;</div>
          <div
            className={cn(
              "hidden desktop:block desktop:w-5/12 text-center tablet:text-left",
              css.columnText
            )}
            dangerouslySetInnerHTML={{
              __html: textCol2?.childMarkdownRemark?.html,
            }}
          />
        </div>

        <div
          className={
            "flex flex-col tablet:flex-row py-6 tablet:py-4 desktop:pt-5 desktop:pb-6 text-center tablet:text-left"
          }
        >
          <div
            className={cn(css.copyText, "w-full tablet:w-6/12 tablet:pr-14")}
            dangerouslySetInnerHTML={{
              __html: legalNoticeText?.childMarkdownRemark?.html,
            }}
          />
          <div className={"hidden desktop:block w-1/24"}>&nbsp;</div>
          <div
            className={"w-full tablet:w-6/12 desktop:w-5/12 mt-7 tablet:mt-0"}
            dangerouslySetInnerHTML={{ __html: AUTHOGRAPH_HTML }}
          />
        </div>
      </footer>
    </div>
  );
}, isEqual);

export default Footer;

export const layoutFooterFragment = graphql`
  fragment LayoutFooterFragment on ContentfulLayoutFooter {
    textCol2 {
      childMarkdownRemark {
        html
      }
    }
    textCol1 {
      childMarkdownRemark {
        html
      }
    }
    partnersBlockTitle
    legalNoticeText {
      childMarkdownRemark {
        html
      }
    }
    linksText {
      childMarkdownRemark {
        html
      }
    }
    partnersRow1 {
      title
      images {
        fluid(
          resizingBehavior: PAD
          maxWidth: 170
          maxHeight: 70
          quality: 100
        ) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    partnersRow2 {
      title
      images {
        fluid(
          resizingBehavior: PAD
          maxWidth: 170
          maxHeight: 70
          quality: 100
        ) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    partnersRow3 {
      title
      images {
        fluid(
          resizingBehavior: PAD
          maxWidth: 170
          maxHeight: 70
          quality: 100
        ) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    partnersRow4 {
      title
      images {
        fluid(
          resizingBehavior: PAD
          maxWidth: 170
          maxHeight: 70
          quality: 100
        ) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    partnersRow5 {
      title
      images {
        fluid(
          resizingBehavior: PAD
          maxWidth: 170
          maxHeight: 70
          quality: 100
        ) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    partnersRow6 {
      title
      images {
        fluid(
          resizingBehavior: PAD
          maxWidth: 170
          maxHeight: 70
          quality: 100
        ) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    partnersRow7 {
      title
      images {
        fluid(
          resizingBehavior: PAD
          maxWidth: 170
          maxHeight: 70
          quality: 100
        ) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    partnersRow8 {
      title
      images {
        fluid(
          resizingBehavior: PAD
          maxWidth: 170
          maxHeight: 70
          quality: 100
        ) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    partnersRow9 {
      title
      images {
        fluid(
          resizingBehavior: PAD
          maxWidth: 170
          maxHeight: 70
          quality: 100
        ) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    partnersRow10 {
      title
      images {
        fluid(
          resizingBehavior: PAD
          maxWidth: 170
          maxHeight: 70
          quality: 100
        ) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`;
