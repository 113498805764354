import React from "react";
import GlobalNav, { AniLink } from "@/components/global-navigation";
import { useRoute } from "@/utils";
import { useLocation } from "@reach/router";
import Hamburger from "@/components/kit/hamburger";
import * as styleConstants from "@/constants/style";
import cn from "classnames";
import MobileMenu from "./mobile-menu";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import AniLogo from "@/components/kit/ani-logo";
import * as css from "./header.modules.scss";
import { useCallback } from "react";

const Header = ({ navLinks, footerLinksText, switchLocaleLink }) => {
  const route = useRoute();
  const location = useLocation();
  const [isHeaderVisible, setHeaderVisible] = React.useState(true);
  const [isMobMenuOpen, setMobMenuOpen] = React.useState(false);
  const toggleMobMenu = () => setMobMenuOpen(!isMobMenuOpen);

  React.useEffect(() => {
    setMobMenuOpen(false);
  }, [location.pathname]);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isVisible = currPos.y > -50 || currPos.y > prevPos.y;

      if (isVisible === isHeaderVisible) return;

      setHeaderVisible(isVisible);
    },
    [isHeaderVisible]
  );

  const isHomePath =
    /^\/(en\/?)?$/.test(location.pathname) ||
    /^\/(en\/)?index-page-seasons\//.test(location.pathname);

  const hideMenuClick = useCallback(() => setMobMenuOpen(false), []);

  return (
    <>
      <header>
        <div
          className={cn(css.desktopHeader, {
            [css.desktopHeaderVisible]: !isHomePath && isHeaderVisible,
          })}
        >
          <div className={styleConstants.container}>
            <GlobalNav
              links={[...navLinks, switchLocaleLink]}
              className={cn("py-3 desktop1280:py-4 text-title-2")}
            />
          </div>
        </div>

        <div
          className={cn("desktop:hidden bg-white px-4", css.mobHeader, {
            [css.mobHeaderVisible]: isMobMenuOpen || isHeaderVisible,
          })}
        >
          <div
            className={cn("flex justify-between items-center h-full", {
              "border-b-2": !isHomePath,
            })}
          >
            <AniLink to={route("home")}>
              <AniLogo className={css.mobLogoIcon} />
            </AniLink>
            <div>
              <Hamburger isOpen={isMobMenuOpen} onClick={toggleMobMenu} />
            </div>
          </div>
        </div>

        <MobileMenu
          links={navLinks}
          isOpen={isMobMenuOpen}
          footerLinksText={footerLinksText}
          switchLocaleLink={switchLocaleLink}
          onClickLink={hideMenuClick}
        />
      </header>
    </>
  );
};

export default Header;
