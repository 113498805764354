import React from "react";
import { Helmet } from "react-helmet";
// import { useStaticQuery, graphql } from "gatsby"
import AOS from "@/plugins/aos";
import DevTools from "@/devTools/index";
import HeaderRu from "@/components/header/index.ru";
import HeaderEn from "@/components/header/index.en";
import FooterRu from "@/components/footer/index.ru";
import FooterEn from "@/components/footer/index.en";
import { RouteProvider } from "@/utils";
import { I18nProvider } from "@/plugins/i18n";
import cn from "classnames";
import { StateProvider } from "@/store";
import { graphql, useStaticQuery } from "gatsby";
import "@/assets/scss/global.scss";

const Layout = ({ children, pageContext }) => {
  const { langKey, messages, layout } = pageContext;

  const { settings } = useStaticQuery(graphql`
    query {
      homePage: contentfulLayoutHome {
        ...HomePageKeyVisuals
      }
      settings: contentfulSiteSettings {
        codeInjections {
          ... on ContentfulCodeInjection {
            __typename
            id
            src {
              src
            }
            type
            codeText {
              codeText
            }
          }
          ... on ContentfulMetaInjection {
            __typename
            id
            name
            property
            content {
              content
            }
          }
        }
      }
    }
  `);

  if (layout === "empty") {
    return (
      <I18nProvider messages={messages}>
        <StateProvider locale={langKey}>
          <RouteProvider locale={langKey}>
            <HeaderRu />
            <HeaderEn />
            <main className={cn("pt-12 desktop:pt-0")}>{children}</main>
          </RouteProvider>
        </StateProvider>
      </I18nProvider>
    );
  }

  return (
    <>
      <Helmet encodeSpecialCharacters={false}>
        {settings &&
          Array.isArray(settings.codeInjections) &&
          settings.codeInjections.map((injection) => {
            if (!injection.__typename) {
              console.warn(
                "CodeInjection: __typename is missing. Something wrong."
              );
            }

            if (injection.__typename === "ContentfulCodeInjection") {
              const { type, src, codeText } = injection;

              switch (type[0]) {
                case "script":
                  if (src) {
                    return <script async key={injection.id} src={src.src} />;
                  }

                  return (
                    <script key={injection.id}>
                      {codeText ? codeText.codeText : ""}
                    </script>
                  );
                case "style":
                  if (src) {
                    return (
                      <link
                        key={injection.id}
                        rel="stylesheet"
                        href={src.src}
                      />
                    );
                  }

                  return (
                    <style key={injection.id}>
                      {codeText ? codeText.codeText : ""}
                    </style>
                  );
                default:
                  return null;
              }
            }

            if (injection.__typename === "ContentfulMetaInjection") {
              const { name, property, content } = injection;

              return (
                <meta
                  key={injection.id}
                  name={name ? name : undefined}
                  property={property ? property : undefined}
                  content={content ? content.content : undefined}
                />
              );
            }

            return null;
          })}
      </Helmet>
      <I18nProvider messages={messages}>
        <StateProvider locale={langKey}>
          <RouteProvider locale={langKey}>
            <AOS />

            <DevTools />
            <HeaderRu />
            <HeaderEn />
            <main className={cn("pt-12 desktop:pt-0")}>{children}</main>
            <FooterRu />
            <FooterEn />
          </RouteProvider>
        </StateProvider>
      </I18nProvider>
    </>
  );
};

export default Layout;
