import React, { createContext, useContext } from "react";
import * as routeNames from "@/constants/paths";
import { DEFAULT_LOCALE } from "@/constants";
import camelCase from "lodash.camelcase";
import { useCallback } from "react";

const appRoutes = Object.keys(routeNames).reduce((acc, key) => {
  acc[camelCase(key)] = routeNames[key];
  return acc;
}, {});

type ExternalLinkProps = {
  rel: string;
  target: "_blank";
};

export const externalLinkProps: Readonly<ExternalLinkProps> = {
  rel: "noopener nofollow",
  target: "_blank",
};

const joinPaths = (pathA, pathB) => {
  if (pathB) {
    if (pathA.endsWith("/")) {
      pathA = pathA.slice(0, -1);
    }

    pathB = pathB.trim();

    if (pathB.endsWith("/")) {
      pathB = pathB.slice(0, -1);
    }

    if (pathB.startsWith("/")) {
      return `${pathA}${pathB}/`;
    } else {
      return `${pathA}/${pathB}/`;
    }
  }

  return pathA;
};

export const localizePath = (locale: string, path: string) => {
  let prefix = locale === DEFAULT_LOCALE ? "" : `/${locale}`;
  return `${prefix}${path}`;
};

const route = (locale: string, routeName: string, path?: string) => {
  if (!appRoutes[routeName]) {
    throw new Error(`route: route with name "${routeName}" is not found`);
  }

  const localizedRoute = localizePath(locale, appRoutes[routeName]);

  if (path) {
    return joinPaths(localizedRoute, path);
  }

  return localizedRoute;
};

const RouteContext = createContext(null);

type RouteProviderProps = {
  locale: string;
};
export const RouteProvider: React.FC<RouteProviderProps> = ({
  locale,
  children,
}) => {
  const valueFn = useCallback(
    (routeName: string, path?: string) => route(locale, routeName, path),
    []
  );

  return (
    <RouteContext.Provider value={valueFn}>{children}</RouteContext.Provider>
  );
};

export const useRoute = () => useContext(RouteContext);
