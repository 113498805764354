export default {
  grid: {
    columns: {
      queries: ["(min-width: 768px)"],
      values: [{ columns: 24, margin: 48, gutter: 0 }],
      defaultValue: { columns: 2, margin: 20, gutter: 0 },
      maxWidth: "144rem",
    },
    // rows: {
    //   queries: ["(min-width: 768px)"],
    //   values: [{ height: 120, offset: 45 }],
    //   defaultValue: { height: 120, offset: 15 },
    // },
  },
};
