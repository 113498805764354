import React, { memo } from "react";
import cn from "classnames";
import LogoSvg from "@/assets/icons/logo.inline.svg";

const ANI_DURATION = 1700;

type AniLogoProps = {
  className?: string;
};

const AniLogo = memo<AniLogoProps>(({ className }) => {
  const aniTimeout = React.useRef(null);
  const imgRef = React.useRef(null);

  const [showAnimation, setAnimation] = React.useState(false);
  const handleMouseOver = () => {
    if (showAnimation) {
      return;
    }

    let raf: ReturnType<typeof requestAnimationFrame>;
    let timer: ReturnType<typeof setTimeout>;

    raf = requestAnimationFrame(() => {
      imgRef.current.style.display = "block";
      imgRef.current.setAttribute(
        "src",
        `${imgRef.current.getAttribute("src").split("?")[0]}?${Date.now()}`
      );

      timer = setTimeout(() => {
        setAnimation(true);
      }, 0);
    });

    return () => {
      raf && cancelAnimationFrame(raf);
      timer && clearTimeout(timer);
    };
  };

  const handleMouseLeave = () => {
    setAnimation(false);
  };
  const handleTouchStart = () => {
    handleMouseOver();

    aniTimeout.current = setTimeout(() => {
      handleMouseLeave();
    }, ANI_DURATION);
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(aniTimeout.current);
    };
  }, []);

  return (
    <div
      className={cn("relative", className)}
      onMouseOver={handleTouchStart}
      onTouchStart={handleTouchStart}
    >
      <LogoSvg
        className={cn("w-full h-auto transition-all duration-500 opacity-0", {
          "opacity-100": !showAnimation,
        })}
      />
      <img
        ref={imgRef}
        src="/images/ani-logo.gif"
        className={cn(
          "absolute top-0 left-0 block w-full h-full transition-all duration-500 opacity-0",
          {
            "opacity-100": showAnimation,
          }
        )}
        alt="logo"
      />
    </div>
  );
});

export default AniLogo;
