/* eslint-disable no-console */
import React, { createContext, useReducer, useContext } from "react";
import * as actionTypes from "@/constants/actionTypes";
import { DEFAULT_LOCALE, LOCALES } from "@/constants";
import { useMemo } from "react";

const initialState = {
  footerIsVisible: false,
  headerWhiteAlways: false,
  locale: "",
  defaultLocale: DEFAULT_LOCALE,
  locales: LOCALES,
};

export const reducer = (state = initialState, action) => {
  let nextState;

  switch (action.type) {
    case actionTypes.SET_HEADER_WHITE_ALWAYS:
      nextState = {
        ...state,
        headerWhiteAlways: action.payload,
      };
      break;

    case actionTypes.SET_FOOTER_VISIBLE:
      nextState = {
        ...state,
        footerIsVisible: action.payload,
      };
      break;
    default:
      nextState = state;
  }

  if (process.env.NODE_ENV !== "production") {
    console.groupCollapsed(action.type);
    console.log("prev state", state);
    console.log("action", action);
    console.log("next state", nextState);
    console.groupEnd();
  }

  return nextState;
};

function dispatchMiddleware(dispatch) {
  return async (action) => {
    switch (action.type) {
      default:
        return dispatch(action);
    }
  };
}

export const StateContext = createContext();

export const StateProvider = ({ children, locale }) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, locale });
  const value = useMemo(() => [state, dispatchMiddleware(dispatch)], [state]);

  return (
    <StateContext.Provider value={value}>{children}</StateContext.Provider>
  );
};

export const useStateValue = () => useContext(StateContext);
