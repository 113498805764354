import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Footer from "@/components/footer/footer";
import { useStateValue } from "@/store";

const IndexRu = () => {
  const data = useStaticQuery(graphql`
    query {
      footer: contentfulLayoutFooter(node_locale: { eq: "ru-RU" }) {
        textCol2 {
          childMarkdownRemark {
            html
          }
        }
        textCol1 {
          childMarkdownRemark {
            html
          }
        }
        partnersBlockTitle
        legalNoticeText {
          childMarkdownRemark {
            html
          }
        }
        linksText {
          childMarkdownRemark {
            html
          }
        }
        partnersRow1 {
          title
          images {
            fluid(
              resizingBehavior: PAD
              maxWidth: 170
              maxHeight: 70
              quality: 100
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        partnersRow2 {
          title
          images {
            fluid(
              resizingBehavior: PAD
              maxWidth: 170
              maxHeight: 70
              quality: 100
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        partnersRow3 {
          title
          images {
            fluid(
              resizingBehavior: PAD
              maxWidth: 170
              maxHeight: 70
              quality: 100
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        partnersRow4 {
          title
          images {
            fluid(
              resizingBehavior: PAD
              maxWidth: 170
              maxHeight: 70
              quality: 100
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        partnersRow5 {
          title
          images {
            fluid(
              resizingBehavior: PAD
              maxWidth: 170
              maxHeight: 70
              quality: 100
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        partnersRow6 {
          title
          images {
            fluid(
              resizingBehavior: PAD
              maxWidth: 170
              maxHeight: 70
              quality: 100
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        partnersRow7 {
          title
          images {
            fluid(
              resizingBehavior: PAD
              maxWidth: 170
              maxHeight: 70
              quality: 100
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        partnersRow8 {
          title
          images {
            fluid(
              resizingBehavior: PAD
              maxWidth: 170
              maxHeight: 70
              quality: 100
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        partnersRow9 {
          title
          images {
            fluid(
              resizingBehavior: PAD
              maxWidth: 170
              maxHeight: 70
              quality: 100
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        partnersRow10 {
          title
          images {
            fluid(
              resizingBehavior: PAD
              maxWidth: 170
              maxHeight: 70
              quality: 100
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  `);

  const [{ locale }] = useStateValue();

  if (locale !== "ru") {
    return null;
  }

  return <Footer data={data} />;
};

export default IndexRu;
