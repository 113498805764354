import * as React from "react";
import useMedia from "@/utils/useMedia";
import { useWindowSize, useLocalStorage } from "react-use";

type ColsProps = {
  columns: number;
  margin: number;
  gutter: number;
  maxWidth: string;
};

const Cols: React.FC<ColsProps> = ({ columns, margin, gutter, maxWidth }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: "50%",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        transform: "translateX(-50%)",
        maxWidth: maxWidth ? maxWidth : undefined,
        padding: margin
          ? `0 ${margin}px` /*  it's padding in css terms and is margin in terms of grids */
          : undefined,
      }}
    >
      {Array.from(Array(columns).keys()).map((i) => (
        <div
          key={i}
          style={{
            width: gutter
              ? `calc(${(1 / columns) * 100}% - ${gutter}px)`
              : `${(1 / columns) * 100}%`,
            height: "100%",
            backgroundColor: gutter !== 0 && "#0080FF",
            opacity: 0.15,
            borderLeft: gutter === 0 && i === 0 && "1px solid red",
            borderRight: gutter === 0 && "1px solid red",
          }}
        />
      ))}
    </div>
  );
};

type RowsProps = {
  height: number;
  offset: number;
};
const Rows: React.FC<RowsProps> = ({ height, offset }) => {
  const { height: winHeight } = useWindowSize();
  const count = Math.ceil(winHeight / height);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        padding: offset && `${offset}px 0 0 0`,
      }}
    >
      {Array.from(Array(count).keys()).map((i) => (
        <div
          key={i}
          style={{
            width: "100%",
            borderTop: "1px solid #0080FF",
            opacity: 0.3,
            height: `${height}px`,
          }}
        />
      ))}
    </div>
  );
};

type ColumnsConfig = {
  queries: Array<string>;
  values: Array<ColsProps>;
  defaultValue?: ColsProps;
  maxWidth: string;
};

type RowsConfig = {
  queries: Array<string>;
  values: Array<RowsProps>;
  defaultValue?: RowsProps;
};

type GridProps = {
  columns?: ColumnsConfig;
  rows?: RowsConfig;
};

const Grid: React.FC<GridProps> = ({ columns, rows }) => {
  const colConfig =
    columns && useMedia(columns.queries, columns.values, columns.defaultValue);
  const rowConfig =
    rows && useMedia(rows.queries, rows.values, rows.defaultValue);

  return (
    <div
      id={"grid"}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        zIndex: 9999999,
        pointerEvents: "none",
      }}
    >
      {colConfig && <Cols {...colConfig} maxWidth={columns.maxWidth} />}
      {rowConfig && <Rows {...rowConfig} />}
    </div>
  );
};

const GridVisibilityWrapper: React.FC<GridProps> = (props) => {
  const { columns, rows } = props;
  const [show, setShow] = useLocalStorage("ony_devTools_grid", false);

  const handleKeyDown = (e) => {
    // control + g
    if (e.ctrlKey && e.keyCode === 71) {
      setShow(!show);
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);

    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, [show]);

  if (!show) {
    return null;
  }

  return <Grid columns={columns} rows={rows} />;
};

export default GridVisibilityWrapper;
