import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const AOSProvider = () => {
  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      ease: "ease-out",
      disableMutationObserver: false,
      anchorPlacement: "top-bottom",
    });
  }, []);

  return null;
};

export default AOSProvider;
