import React from "react";
import cn from "classnames";
import { Portal } from "react-portal";
import useBodyScrollLock from "@/plugins/useBodyScrollLock";
import { GlobalNavLink } from "@/components/global-navigation/index";
import { useLocation } from "@reach/router";
import * as css from "./header.modules.scss";

type MobileMenuProps = {
  isOpen: boolean;
  links: GlobalNavLink[];
  switchLocaleLink: GlobalNavLink;
  footerLinksText?: string;
  onClickLink?: () => void;
};
const MobileMenu: React.FC<MobileMenuProps> = ({
  isOpen,
  links,
  footerLinksText,
  switchLocaleLink,
  onClickLink,
}) => {
  const [setLock, windowScrollRef] = useBodyScrollLock();

  React.useEffect(() => {
    setLock(isOpen);

    return () => {
      setLock(false);
    };
  }, [isOpen]);

  const location = useLocation();
  const isHomePath = /^\/(en\/?)?$/.test(location.pathname);

  return (
    <Portal key="mobile-menu-portal">
      <div
        className={cn("desktop:hidden", css.mobileMenuRoot, {
          [css.isOpen]: isOpen,
        })}
      >
        <div className={cn("px-4 ", css.mobileMenuInner)} ref={windowScrollRef}>
          <div className={"flex flex-col"}>
            <div className="flex flex-col w-full">
              {links.map(({ to, children, href, ...rest }, i) => {
                return to ? (
                  <GlobalNavLink
                    key={i}
                    activeClassName="font-extrabold"
                    className={cn("text-h3 uppercase hover:no-underline py-3", {
                      "border-t-2": isHomePath ? true : i > 0,
                    })}
                    to={to}
                    onClick={onClickLink}
                  >
                    {children}
                  </GlobalNavLink>
                ) : (
                  <a
                    key={i}
                    href={href}
                    className={
                      "text-h3 uppercase hover:no-underline py-3 border-t-2"
                    }
                    onClick={onClickLink}
                    {...rest}
                  >
                    {children}
                  </a>
                );
              })}
            </div>

            <div className="flex mt-8">
              <div className="w-1/2">
                <a
                  href={switchLocaleLink.href}
                  target={switchLocaleLink.target}
                  className={cn(css.switchLocaleLink, "uppercase")}
                >
                  {switchLocaleLink.children}
                </a>
              </div>
              {footerLinksText && (
                <div
                  className={cn(
                    "w-1/2 text-title-2 uppercase",
                    css.footerLinksText
                  )}
                  dangerouslySetInnerHTML={{ __html: footerLinksText }}
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default MobileMenu;
